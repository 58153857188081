import {Injectable, OnDestroy, OnInit} from '@angular/core';
import {interval, Observable} from 'rxjs';
import {takeWhile} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {UserInfoService} from './userinfo.service';
import {Subscription} from 'rxjs/Subscription';
import 'rxjs/Rx';
import {BehaviorSubject} from 'rxjs';
import {BlockUI, NgBlockUI} from 'ng-block-ui';

import * as moment from 'moment';

@Injectable()
export class DashboardQueryService implements OnDestroy, OnInit {
    private alive = true;

    appGroupId: number;
    projectId: number;
    projectId$ = new BehaviorSubject<number>(this.projectId);
    transformerId: number;
    transformerId$ = new BehaviorSubject<number>(this.transformerId);

    intervalSubscription: Subscription;

    // Data part
    statsData: object = {
        data: {},
        isChangeTransformer: true,
    };
    statsData$ = new BehaviorSubject(this.statsData);
    // lifetimeData: any;
    // lifetimeData$ = new BehaviorSubject(this.lifetimeData);
    powerRealtimeData: any;
    powerRealtimeData$ = new BehaviorSubject(this.powerRealtimeData);

    projectsInfo: object[] = [];
    projectsInfo$ = new BehaviorSubject<object[]>(this.projectsInfo);
    defaultProjectId: number;
    defaultProjectId$ = new BehaviorSubject<number>(this.defaultProjectId);
    transformerInfo: object[] = [];
    transformerInfo$ = new BehaviorSubject<object[]>(this.transformerInfo);

    projectSaveMoney: object = {
        value: 0,
        isChangeProject: true,
    };
    projectSaveMoney$ = new BehaviorSubject<object>(this.projectSaveMoney);

    deviceInfo: object[];
    deviceInfo$ = new BehaviorSubject<object[]>(this.deviceInfo);

    needProject: boolean = true;
    needProject$ = new BehaviorSubject<boolean>(this.needProject);

    @BlockUI('stats-block') StatsBlock: NgBlockUI;
    @BlockUI('flowchart-block') FlowchartBlock: NgBlockUI;
    @BlockUI('power-block') PowerBlock: NgBlockUI;
    @BlockUI('save-block') SaveBlock: NgBlockUI;

    constructor(private http: HttpClient,
                private userInfoService: UserInfoService) {
    }

    ngOnInit() {
        // this.queryProjectInfo();
    }

    queryProjectInfo() {
        this.http.post('/management/project/list', {
            app_group_id: this.userInfoService.getAppGroupId(),
        }).subscribe(data => {
            if (data['default_project_id']) {
                this.defaultProjectId = data['default_project_id'];
                this.projectId = data['default_project_id'];
            } else {
                this.defaultProjectId = data['data'][0]['project_id'];
                this.projectId = data['data'][0]['project_id'];
            }
            this.projectId$.next(this.projectId);
            this.projectsInfo = data['data'];
            this.projectsInfo$.next(this.projectsInfo);
            this.queryProjectSaveMoney(this.projectId, true);
            this.queryTransformerInfo(this.projectId);
        })
    }

    queryProjectSaveMoney(projectId, isChangeProject) {
        this.http.post('/dashboard/save_money/project', {
            project_id: projectId,
            query_time: moment().unix(),
        }).subscribe(data => {
            this.projectSaveMoney['value'] = data['money'];
            this.projectSaveMoney['isChangeProject'] = isChangeProject;
            this.projectSaveMoney$.next(this.projectSaveMoney);
        })
    }

    queryTransformerInfo(projectId) {
        this.http.get('/management/transformer/list', {
            params: {
                project_id: projectId.toString(),
            }
        }).subscribe(data => {
            this.transformerInfo = data['data'];
            this.transformerInfo$.next(this.transformerInfo);
        })
    }

    setProject(projectId) {
        this.projectId = projectId;
        this.projectId$.next(this.projectId);
        this.queryProjectSaveMoney(projectId, true);
        this.queryTransformerInfo(projectId);
    }

    setNeedProject(Boo) {
        this.needProject = Boo;
        this.needProject$.next(this.needProject);
    }

    queryData(url, postBody) {
        return this.http.post(url, postBody);
    }

    changeTransformer(id) {
        this.transformerId = id;
        this.transformerId$.next(this.transformerId);
        this.StatsBlock.start('');
        this.FlowchartBlock.start('');
        this.PowerBlock.start('');
        this.SaveBlock.start('');
        this.stopReceivingIntervalData();
        this.queryDeviceInfo();
        this.queryStats(true);
        this.queryPowerRealtime();
        this.startReceivingIntervalData();
    }

    queryDeviceInfo() {
        this.http.get('/management/device/list', {
            params: {
                transformer_id: this.transformerId.toString(),
            }
        }).subscribe(data => {
            this.deviceInfo = data['data'];
            this.deviceInfo$.next(this.deviceInfo);
        })
    }

    queryStats(isChangeTransformer) {
        this.http.post('/dashboard/stats', {
            transformer_id: this.transformerId,
        }).subscribe(data => {
            this.statsData['data'] = data;
            this.statsData['isChangeTransformer'] = isChangeTransformer;
            this.statsData$.next(this.statsData);
            this.StatsBlock.stop();
        })
    }

    // queryLifetime() {
    //   const url = '/saved_money/lifetime';
    //   this.appGroupId = this.userInfoService.getAppGroupId();
    //   const postBody = {
    //     appGroupId: this.appGroupId,
    //   };
    //   this.queryData(url, postBody).subscribe(data => {
    //     this.lifetimeData = data;
    //   }, error => {
    //     if (error.status === 400) {
    //       if (error.error['message'] === 'dataserver.database: device not exist or template not find') {
    //         this.lifetimeData = {
    //           month: 0,
    //           lifetime: 0,
    //           month_energy: 0,
    //           lifetime_energy: 0,
    //         };
    //       }
    //     }
    //   });
    // }

    // getLifetimeData() {
    //   return this.lifetimeData;
    // }

    checkLifetimeData() {
        return Observable.create(observer => {
            const interval_id = setInterval(() => {
                // if (this.statsData && this.powerRealtimeData) {
                //   observer.next(this.statsData);
                //   observer.complete();
                //   clearInterval(interval_id);
                // }
                if (this.transformerInfo) {
                    observer.next(this.transformerInfo);
                    observer.complete();
                    clearInterval(interval_id);
                }
            }, 100);
        });
    }

    queryPowerRealtime() {
        const url = '/dashboard/power/realtime';
        // this.appGroupId = this.userInfoService.getAppGroupId();
        // const postBody = {
        //   appGroupId: this.appGroupId,
        //   begin_time: (moment().startOf('minute').valueOf() / 1000 - 3600),
        //   end_time: moment().startOf('minute').valueOf() / 1000 - 60,
        //   period: '60m',
        //   unit: 'minute',
        // };
        this.http.post(url, {
            transformer_id: this.transformerId,
        }).subscribe(data => {
            this.powerRealtimeData = data;
            this.powerRealtimeData$.next(this.powerRealtimeData);
            this.FlowchartBlock.stop();
        }, error => {
            if (error.status === 400) {
                if (error.error['message'] === 'dataserver.database: device not exist or template not find') {
                    this.powerRealtimeData = {
                        charge_power: '0',
                        discharge_power: '0',
                        tranformer_power: '0',
                    };
                }
            }
        });
    }

    getPowerRealtimeData() {
        return this.powerRealtimeData;
    }

    getPowerHistoryData(postBody) {
        const url = '/dashboard/power/history';
        return this.queryData(url, postBody);
    }

    getSavedMoneyHistory(postBody) {
        const url = '/dashboard/save_money/history';
        return this.queryData(url, postBody);
    }

    startReceivingIntervalData() {
        if (this.intervalSubscription) {
            this.intervalSubscription.unsubscribe();
        }

        this.intervalSubscription = interval(60000)
            .pipe(
                takeWhile(() => this.alive),
            )
            .subscribe(() => {
                this.queryStats(false);
                this.queryProjectSaveMoney(this.projectId, false);
                // this.queryLifetime();
                this.queryPowerRealtime();
            });
    }

    stopReceivingIntervalData() {
        if (this.intervalSubscription) {
            this.intervalSubscription.unsubscribe();
        }
    }

    stopPowerBlock() {
        this.PowerBlock.stop();
    }

    stopSaveBlock() {
        this.SaveBlock.stop();
    }

    ngOnDestroy() {
        this.alive = false;
    }
}

