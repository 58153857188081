import {Injectable} from '@angular/core';
import {PeriodsService} from './periods.service';

export class ProfitChart {
    chartLabel: string[];
    data: number[][];
}

@Injectable()
export class ProfitChartService {

    private year = [
        '2012',
        '2013',
        '2014',
        '2015',
        '2016',
        '2017',
        '2018',
    ];

    private data = {};

    constructor(private period: PeriodsService) {
        this.data = {
            '过去7天': this.getDataForWeekPeriod(),
            '过去30天': this.getDataForMonthPeriod(),
            '过去12个月': this.getDataForYearPeriod(),
        };
    }

    private getDataForWeekPeriod(): ProfitChart {
        const nPoint = this.period.getWeeks().length;
        const a = this.getRandomData2(nPoint);
        const b = this.getRandomData(nPoint);

        function arrayPlus(item1, item2) {
            const c = [];
            for (let i = 0; i < nPoint; i++) {
                c.push(item1[i] - item2[i]);
            }
            return c;
        }

        return {
            chartLabel: this.period.getWeeks(),
            data: [
                b,
                a,
                arrayPlus(b, a),
            ],
        };
    }

    private getDataForMonthPeriod(): ProfitChart {
        const nPoint = this.period.getMonths().length;
        const a = this.getRandomData2(nPoint);
        const b = this.getRandomData(nPoint);

        function arrayPlus(item1, item2) {
            const c = [];
            for (let i = 0; i < nPoint; i++) {
                c.push(item1[i] - item2[i]);
            }
            return c;
        }

        return {
            chartLabel: this.period.getMonths(),
            data: [
                b,
                a,
                arrayPlus(b, a),
            ],
        };
    }

    private getDataForYearPeriod(): ProfitChart {
        const nPoint = this.year.length;
        const a = this.getRandomData2(nPoint);
        const b = this.getRandomData(nPoint);

        function arrayPlus(item1, item2) {
            const c = [];
            for (let i = 0; i < nPoint; i++) {
                c.push(item1[i] - item2[i]);
            }
            return c;
        }

        return {
            chartLabel: this.year,
            data: [
                b,
                a,
                arrayPlus(b, a),
            ],
        };
    }

    private getRandomData(nPoints: number): number[] {
        return Array.from(Array(nPoints)).map(() => {
            return Math.round(Math.random() * 500);
        });
    }

    private getRandomData2(nPoints: number): number[] {
        return Array.from(Array(nPoints)).map(() => {
            return Math.round((Math.random() - 0.5) * 200);
        });
    }

    getProfitChartData(period: string): ProfitChart {
        return this.data[period];
    }
}
