import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';

import {NbMenuService, NbSidebarService, NbDateService} from '@nebular/theme';
import {NbAuthService} from '@nebular/auth';
import {UserService} from '../../../@core/data/users.service';
import {UserInfoService} from '../../../@core/info/userinfo.service';
import {AnalyticsService} from '../../../@core/utils/analytics.service';
import {DashboardQueryService} from '../../../@core/info/dashboard_query.service';
import * as moment from 'moment';
import {saveAs} from 'file-saver';
import {BlockUI, NgBlockUI} from 'ng-block-ui';

@Component({
    selector: 'ngx-header',
    styleUrls: ['./header.component.scss'],
    templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {


    @Input() position = 'normal';

    alive: boolean = true;

    total_save: number = 0;
    new_total_save: number = 0;
    option1 = {
        startVal: this.total_save,
        useEasing: true,
        duration: 2,
        decimalPlaces: 2,
    };

    projects: Object[] = [];
    save_money_month: Object[] = [{month_key: "本月", month_num: 0}, {month_key: "上月", month_num: 1}];
    project_setting: Object = {
        singleSelection: true,
        text: '选择项目',
        enableSearchFilter: false,
        showCheckbox: false,
        maxHeight: 400,
        noDataLabel: '没有园区信息',
        labelKey: 'project_name',
        primaryKey: 'project_id',
    };
    month_setting: Object = {
        singleSelection: true,
        text: '选择账单导出月份',
        enableSearchFilter: false,
        showCheckbox: false,
        maxHeight: 400,
        noDataLabel: 'NA',
        labelKey: 'month_key',
        primaryKey: 'month_num',
        classes: "month-select",
    };
    selectedProject: Object[];
    selectedMonth: Object[] = [{month_key: '节约电费账单导出', month_num: 99}];
    defaultProjectId: number;
    isDefault: boolean = true;
    need_project: boolean = true;
    group_id: number;
    username: string;

    exportTime: Date = this.dateService.today(); // 导出账单时间 默认本月

    @BlockUI('download-block') DownloadBlock: NgBlockUI;

    constructor(private sidebarService: NbSidebarService,
                private menuService: NbMenuService,
                private userService: UserService,
                private userInfoService: UserInfoService,
                private analyticsService: AnalyticsService,
                private authService: NbAuthService,
                private http: HttpClient,
                private dashboardQueryService: DashboardQueryService,
                protected dateService: NbDateService<Date>,
                private router: Router) {
        this.group_id = this.userInfoService.getAppGroupId();
        this.dashboardQueryService.queryProjectInfo();
        this.dashboardQueryService.projectsInfo$.subscribe(info => {
            this.defaultProjectId = this.dashboardQueryService.defaultProjectId;
            this.projects = [];
            for (let i = 0; i < info.length; i++) {
                this.projects.push({
                    project_id: info[i]['project_id'],
                    project_name: info[i]['name'],
                });
                if (info[i]['project_id'] === this.defaultProjectId) {
                    this.selectedProject = [{
                        project_id: info[i]['project_id'],
                        project_name: info[i]['name'],
                    }]
                }
            }
        });
        this.dashboardQueryService.needProject$.subscribe(value => {
            this.need_project = value;
        });
    }

    ngOnInit() {
        // this.new_total_save = this.dashboardQueryService.projectSaveMoney;
        this.dashboardQueryService.projectSaveMoney$.subscribe(value => {
            if (value['isChangeProject']) {
                this.total_save = 0;
                this.option1 = {
                    startVal: 0,
                    useEasing: false,
                    duration: 2,
                    decimalPlaces: 2,
                };
            }
            this.new_total_save = value['value'];
            if (this.total_save > 0) {
                this.option1 = {
                    startVal: this.total_save,
                    useEasing: false,
                    duration: 60,
                    decimalPlaces: 2,
                };
            }
            this.total_save = this.new_total_save;
        });
        this.username = this.userInfoService.getUserName();
    }

    toggleSidebar(): boolean {
        this.sidebarService.toggle(true, 'menu-sidebar');
        return false;
    }

    onProjectSelect(project: any) {
        this.isDefault = project['project_id'] === this.defaultProjectId;
        this.dashboardQueryService.setProject(project['project_id']);
    }

    onProjectDeSelect(project: any) {
        this.selectedProject.push(project);
    }

    onMonthSelect() {
        let year = moment(this.exportTime).year();
        let month = moment(this.exportTime).month();
        let start_time = moment([year, month]).startOf('month').format('YYYY-MM-DD HH:mm:ss');
        let end_time = moment([year, month]).endOf('month').format('YYYY-MM-DD HH:mm:ss');
        // console.log('1==', start_time)
        // console.log('2==', end_time)
        /*this.selectedMonth = [{month_key: '节约电费账单导出', month_num: 99}];
        let start_time;
        let end_time;
        if (month.month_num === 0) {
            start_time = moment().startOf('month').format('YYYY-MM-DD HH:mm:ss');
            end_time = moment().format('YYYY-MM-DD HH:mm:ss');
        } else if (month.month_num === 1) {
            start_time = moment().startOf('month').subtract(1, 'months').format('YYYY-MM-DD HH:mm:ss');
            end_time = moment().startOf('month').format('YYYY-MM-DD HH:mm:ss');
        }*/

        this.DownloadBlock.start('');
        this.http.post('/dashboard/save_money_energy/export', {
            project_id: this.selectedProject[0]['project_id'],
            begin_time: start_time,
            end_time: end_time,
        }, {responseType: 'blob'}).subscribe((res) => {
            this.DownloadBlock.stop();
            const filename = this.selectedProject[0]['project_name'] + "电费账单" + start_time + '--' + end_time;
            saveAs(res, filename + '.xlsx');
        }, error => {
            this.DownloadBlock.stop();
            alert(error);
        });
    }

    onMonthDeselect(month: any) {
        this.onMonthSelect();
    }

    setDefault() {
        this.http.post('/project_manage/default_project_setting', {
            default_project_id: this.selectedProject[0]['project_id'],
        }).subscribe(data => {
            if (data['success']) {
                this.defaultProjectId = this.selectedProject[0]['project_id'];
                this.isDefault = true;
            }
        }, error => {
            alert('默认园区设置失败,原因:' + error['message']);
        })
    }

    goToHome() {
        this.menuService.navigateHome();
    }

    logout() {
        const that = this;
        this.http.post('/logout', {
            appUserId: this.userInfoService.getAppGroupId(),
            username: this.userInfoService.getUserName(),
        }).subscribe(data => {
            that.router.navigate(['/auth/login']);
        });
    }

    ngOnDestroy() {
        this.userInfoService.clearAllinfo();
        this.alive = false;
    }
}
