import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router} from '@angular/router';
import {NbAuthJWTToken, NbAuthService} from '@nebular/auth';

@Injectable()
export class IsLoginService implements CanActivate {

    constructor(private router: Router,
                private authService: NbAuthService) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        let auth_uuid;
        this.authService.getToken()
            .subscribe((token: NbAuthJWTToken) => {
                if (token.getPayload())
                    auth_uuid = token.getPayload().auth_uuid;
        });
        if (auth_uuid) {
            return true;
        } else {
            this.router.navigate(['/auth/login']);
            return false;
        }
    }
}