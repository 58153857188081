import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {NbAuthJWTToken, NbAuthService} from '@nebular/auth';
import {Observable} from 'rxjs';

@Injectable()
export class UserInfoService {
  private userName: string;
  private token: string;
  private appGroupId: number;
  private deviceInfo: any = null;
  private role: string;
  private expTime: number;

  constructor(private http: HttpClient,
              private authService: NbAuthService) {
  }

  queryDeviceInfo() {
    this.authService.onTokenChange()
      .subscribe((token: NbAuthJWTToken) => {
        if (token.isValid()) {
          this.token = token.getValue();
          this.userName = token.getPayload().username;
          this.appGroupId = token.getPayload().app_group_id;
          this.role = token.getPayload().role;
          this.expTime = token.getPayload().exp;
          this.http.post('/management/device/get', {
            app_group_id: this.appGroupId,
          }).subscribe(result => {
              this.deviceInfo = result;
            });
          // });
        }
      });
  }


  refreshDeviceInfo() {
    this.deviceInfo = null;
    this.http.post('/management/device/get', {
      app_group_id: this.appGroupId,
    }).subscribe(result => {
        this.deviceInfo = result;
      });
  }

  checkAppGroupId() {
    this.queryDeviceInfo();
    return Observable.create(observer => {
      const interval_id = setInterval(() => {
        if (this.appGroupId >= 0 && this.deviceInfo !== null) {
        // if (this.appGroupId >= 0) {
          observer.next(this.appGroupId);
          observer.complete();
          clearInterval(interval_id);
        }
      }, 100);
    });
  }

  getUserName() {
    return this.userName;
  }

  getToken() {
    return this.token;
  }

  getAppGroupId() {
    return this.appGroupId;
  }

  getDeviceInfo() {
    return this.deviceInfo;
  }

  getRole() {
    return this.role;
  }

  getExpTime() {
    return this.expTime;
  }

  clearAllinfo() {
    this.token = null;
    this.userName = null;
    this.appGroupId = null;
    this.role = null;
    this.expTime = null;
    this.deviceInfo = null;
  }
}

