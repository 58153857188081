import { Component } from '@angular/core';

@Component({
  selector: 'ngx-footer',
  styleUrls: ['./footer.component.scss'],
  template: `
    <span class="created-by">© 2019 南京四象新能源科技有限公司&nbsp;/
        <b><a href="http://www.tmrenergy.com/" target="_blank">TMR Energy Co.,Ltd  </a></b>保留所有权利。
    </span>
    <!--<div class="socials">-->
      <!--<a href="#" target="_blank" class="ion ion-social-github"></a>-->
      <!--<a href="#" target="_blank" class="ion ion-social-facebook"></a>-->
      <!--<a href="#" target="_blank" class="ion ion-social-twitter"></a>-->
      <!--<a href="#" target="_blank" class="ion ion-social-linkedin"></a>-->
    <!--</div>-->
  `,
})
export class FooterComponent {
}
