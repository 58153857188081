
import { of as observableOf,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';


let counter = 0;

@Injectable()
export class UserService {

  private users = {
    nick: { username: 'Nick Jones', picture: 'assets/images/nick.png' },
    eva: { username: 'Eva Moor', picture: 'assets/images/eva.png' },
    jack: { username: 'Jack Williams', picture: 'assets/images/jack.png' },
    lee: { username: 'Lee Wong', picture: 'assets/images/lee.png' },
    alan: { username: 'Alan Thompson', picture: 'assets/images/alan.png' },
    kate: { username: 'Kate Martinez', picture: 'assets/images/kate.png' },
  };

  private userArray: any[];

  constructor() {
    // this.userArray = Object.values(this.users);
  }

  getUsers(): Observable<any> {
    return observableOf(this.users);
  }

  getUserArray(): Observable<any[]> {
    return observableOf(this.userArray);
  }

  getUser(): Observable<any> {
    counter = (counter + 1) % this.userArray.length;
    return observableOf(this.userArray[counter]);
  }
}
